<template>
  <div class="yellow lighten-5" id="fullheight">
    <v-container>
      <feedbackmsg :msgpara="msgpara" @msgClose="msgClose"/>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-card id="cartRadius" class="">
                <v-card-title>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <p class="ma-0 headline">Search Code</p>
                      <v-text-field
                        outlined
                        hide-details
                        v-model="searchcode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-btn @click="searchAreacode" block x-large rounded  color="yellow darken-4 headline" dark>Search</v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              
              <v-card id="cartRadius" class="mt-3">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12">
                      <p class="ma-0 headline">Areacode</p>
                      <v-text-field
                        v-model="areacode"
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <p class="ma-0 headline">Area</p>
                      <v-text-field
                        v-model="area"
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <p class="ma-0 headline">Address</p>
                      <v-textarea
                        v-model="address"
                        outlined
                        readonly
                        hide-details
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import feedbackmsg from '@/components/FeedbackMsg'
  import Localbase from 'localbase'

  let db = new Localbase('acezcodedb')  
  db.config.debug = false

  export default {
    name: 'MainSearch',
    components: { feedbackmsg },  
    data: () => ({
      acezcode: null,
      areacode: null,
      searchcode: null,
      address: null,
      area: null,
      msgpara: { header: '', icon: null, message: '',
                  iserr: false, closeonly: false, yesno: false, yesnocancel: false, showmsg: false
                },
    }),
    methods: {
      searchAreacode(){
        let cSrch = this.searchcode
        this.searchcode = null
        if(cSrch){
            db.collection('customer').doc(cSrch).get().then(document => {
              //console.log('doc: '+document.address)
              if(document){
                  this.acezcode = document.acezcode
                  this.areacode = document.areacode
                  this.address = document.address
                  this.area = document.area
              }else{
                  this.msgpara.header = 'Error'
                  this.msgpara.message = 'Invalid Code'
                  this.msgpara.yesno = false
                  this.msgpara.closeonly = true
                  this.msgpara.showmsg = true
                  this.msgpara.iserr = true
              }
            })
        }else{
            this.msgpara.header = 'Error'
            this.msgpara.message = 'Invalid Code'
            this.msgpara.yesno = false
            this.msgpara.closeonly = true
            this.msgpara.showmsg = true
            this.msgpara.iserr = true
        }
      },
      msgClose() {
          this.msgpara.header = ''
          this.msgpara.message = ''
          this.msgpara.yesno = false
          this.msgpara.closeonly = false
          this.msgpara.showmsg = false
      }
    },
    mounted(){
    }
  }
</script>
