<template>
  <div class="text-center pt-10">
     <feedbackmsg :msgpara="msgpara" @msgYes="msgYes" @msgNo="msgNo" @msgClose="msgClose"/>
     <v-container>
       <v-row align="center" justify="center">
         <v-col cols="8" sm="8">
           <v-btn rounded color="primary" @click="confirmUpdate" block x-large>Update List</v-btn> 
         </v-col>
         <v-col cols="8" sm="8">
          <p class="ma-0 headline">Starting Code</p>
          <v-text-field
            outlined
            hide-details
            v-model="areacode"
            readonly
          ></v-text-field>
         </v-col>
       </v-row>
     </v-container>

     <v-overlay
        :value="showLoading"
     >
        <v-progress-circular indeterminate size="120">
           {{loadingmsg}}
        </v-progress-circular>
     </v-overlay>

     <v-overlay
        :value="showRekkount"
     >
        <v-progress-circular indeterminate size="120">
           {{rekkountmsg}}
        </v-progress-circular>
     </v-overlay>  

     <v-overlay :value="updateDone">
      <v-card id="cartRadius" light>
        <v-card-text class="mb-0 pb-0">
          <v-icon size="120" class="mb-4" color="success">
            fas fa-check-circle
          </v-icon>
          <!-- <v-avatar  size="120">
            <v-icon size="60">
              fas fa-check-circle
            </v-icon>
          </v-avatar> -->
          <p class="title pa-0 font-weight-light">
            Downloading Database done!
          </p>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0">
          <v-btn block color="success" to="/mainmenu">
            Done
          </v-btn>
        </v-card-actions>
      </v-card>  
     </v-overlay>  
  </div>
</template>

<script>
  import feedbackmsg from '@/components/FeedbackMsg'
  import axios from 'axios'
  import {API_URL} from '@/js/constantvars'
  import Localbase from 'localbase'

  let db = new Localbase('acezcodedb')  
  db.config.debug = false

  export default {
    name: 'Update',
    components: { feedbackmsg },  
    data() {
        return {
          ctrlinc: 1000000,
          updateDone: false,
          showLoading: false,
          showRekkount: false,
          rekkountmsg: '',
          loadingmsg: 'Retrieving Records...',
          areacode: 0,
          data: [],
          msgpara: { header: '', icon: null, message: '',
                  iserr: false, closeonly: false, yesno: false, yesnocancel: false, showmsg: false
                 },
          }
    },

    methods: {
      async downloadReks(){
            this.updateDone = false
            this.areacode = 0
            while(!this.updateDone){
                this.showLoading = true
                this.showRekkount = false
                this.data = []
                await this.getLastAreaCode()
                await this.getCustList()
                //console.log('data: '+this.data)
                this.showLoading = false
                this.showRekkount = true
                await this.processData()
            }
       },

      async getLastAreaCode(){
        //db.collection('customer').orderBy('acezcode', 'desc').limit(1).get().then(cust => {
        try {
            //const cust = await db.collection('customer').orderBy('areacode', 'desc').limit(1).get()
            const cust = await db.collection('customer').orderBy('ctrlinc', 'desc').limit(1).get()
            if(cust.length > 0){
                this.areacode = cust[0].areacode
                this.ctrlinc = cust[0].ctrlinc
                console.log('cust: ', cust[0].areacode)
            }
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
      },

      async getCustList(){
        var oRecord = [
          { acezcode: this.areacode, firstname: '', lastname: '',  
            address: '', mobile1: '', unit: '',
            driver: '', btime: '', bdate: '' 
          }
        ]
        try {//serverIP
            const resp = await axios.post(API_URL+'/acezcodeget/',oRecord)
            //const resp = await axios.post('http://'+this.serverIP+':8764/acezcodeget/',oRecord)
            this.data = resp.data 
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
      },

      async processData(){
        if(this.data.length>0){
          //console.log('data 2')
          this.showLoading = false
          this.showRekkount = true
          let iacezcode = null 
          let areacode = null 
          let iareacode = null 
          let address = null
          let area = null
          let reknum = 0
          for(var i=0;i<this.data.length;i++){
            ++reknum
            ++this.ctrlinc
            //console.log("Rec: "+reknum.toString())
            this.rekkountmsg = reknum.toString() + ' / ' + this.data.length.toString()
            iacezcode = this.data[i].acezcode
            areacode = this.data[i].areacode.toString()
            iareacode = this.data[i].areacode
            address = this.data[i].address
            area = this.data[i].area
            await db.collection('customer').doc(areacode).set({
                areacode: iareacode, 
                address: address,
                area: area,
                acezcode: iacezcode, 
                ctrlinc: this.ctrlinc
            })
          } 
          console.log('Tapos na Update') 
          //this.showLoading = false
          //this.showRekkount = false
        }else{
            this.showRekkount = false
            this.updateDone = true
        }
      },

      confirmUpdate(){
          this.msgpara.header = 'Confirm'
          this.msgpara.message = 'Proceed to update List?'
          this.msgpara.yesno = true
          this.msgpara.closeonly = false
          this.msgpara.showmsg = true
          this.msgpara.iserr = false
      },
      msgNo() {
          this.msgpara.header = ''
          this.msgpara.message = ''
          this.msgpara.yesno = false
          this.msgpara.closeonly = false
          this.msgpara.showmsg = false
      },
      msgYes() {
          this.msgpara.header = ''
          this.msgpara.message = ''
          this.msgpara.yesno = false
          this.msgpara.closeonly = false
          this.msgpara.showmsg = false
          this.downloadReks()
      },
      msgClose() {
          this.msgpara.header = ''
          this.msgpara.message = ''
          this.msgpara.yesno = false
          this.msgpara.closeonly = false
          this.msgpara.showmsg = false
      },
      showErr() {
          this.msgpara.header = 'Info'
          this.msgpara.message = 'Problem contacting Server'
          this.msgpara.yesno = false
          this.msgpara.closeonly = true
          this.msgpara.showmsg = true
          this.msgpara.iserr = true
      },
    }
  }
</script>

